import { useEffect, useState } from "react";

import parse from "html-react-parser";

import GetImageByID from "../../utils/functions/getImageById";

import classes from "./programItem.module.scss";

const ProgramItem = ({ image, content, isEven }) => {
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      const res = await GetImageByID(image);
      setImageSrc(res);
    };

    fetchImage();
  }, []);

  return (
    <div
      className={`${classes.programItem} ${
        isEven ? classes.even : classes.odd
      }`}
    >
      <img src={imageSrc} />
      <div className={classes.contentContainer}>{parse(content)}</div>
    </div>
  );
};

export default ProgramItem;
