import parse from "html-react-parser";

import classes from "./candidateItem.module.scss";
import { useEffect, useState } from "react";
import GetImageByID from "../../utils/functions/getImageById";

const CandidateItem = ({ imageId, name, description }) => {
  const [image, setImage] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      const res = await GetImageByID(imageId);
      setImage(res);
    };

    fetchImage();
  }, []);

  return (
    <div className={classes.candidateItem}>
      <img
        src={image}
        className={description === "" ? classes.imageFull : undefined}
      />
      <h3>{name}</h3>
      {description !== "" && parse(description)}
    </div>
  );
};

export default CandidateItem;
