import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "../../styles/swiperStyles.scss";

import { useContext } from "react";
import { DataContext } from "../../store/data-context";

import parse from "html-react-parser";

import classes from "./candidates.module.scss";
import CandidateItem from "./CandidateItem";

const Candidates = () => {
  const { data } = useContext(DataContext);

  return (
    <div className={classes.candidates} id="kandydaci-na-radnych">
      <div className={classes.titleContainer}>
        <div className={classes.titleSidebar}></div>
        <div className={classes.title}>{parse(data.homeCFS.s_con_title)}</div>
      </div>
      <div className={classes.candidatesContainer}>
        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={60}
          autoplay={{
            delay: 9999900,
            disableOnInteraction: false,
          }}
          loop={true}
          slidesPerView={"auto"}
          centeredSlides={true}
          navigation
        >
          {Object.values(data.homeCFS.s_con_loop).map((item, index) => (
            <SwiperSlide key={index}>
              <CandidateItem
                imageId={item.photo}
                name={item.name}
                description={item.description}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Candidates;
