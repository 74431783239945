import { useContext, useState } from "react";

import useIsMobile from "../../utils/hooks/use-is-mobile";

import { DataContext } from "../../store/data-context";
import { FaBars } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

import classes from "./header.module.scss";
import MobileNavigation from "./MobileNavigation";

const Header = () => {
  const [isMobileNavActive, setIsMobileNavActive] = useState(false);
  const { data } = useContext(DataContext);
  const isMobile = useIsMobile();

  const onCloseMenu = () => {
    setIsMobileNavActive(false);
    document.body.classList.remove("modal-open");
  };

  const navigation = isMobile ? (
    <li
      className={classes.burger}
      onClick={() => {
        setIsMobileNavActive((prevValue) => {
          if (prevValue) {
            document.body.classList.remove("modal-open");
          } else {
            document.body.classList.add("modal-open");
          }

          return !prevValue;
        });
      }}
    >
      {isMobileNavActive ? <IoClose /> : <FaBars />}
    </li>
  ) : (
    <>
      {data.menus.primary.map((item, index) => (
        <li key={index}>
          <a href={item.url}>{item.title}</a>
        </li>
      ))}
      <li key="wykaz">
        <a
          href="#"
          onClick={() => {
            window.open(data.homeCFS.rejestr_wplat);
          }}
        >
          Rejestr wpłat
        </a>
      </li>
    </>
  );

  return (
    <>
      <nav className={classes.header}>
        <ul>{navigation}</ul>
      </nav>
      {isMobile && (
        <MobileNavigation
          isActive={isMobileNavActive}
          closeHandler={onCloseMenu}
        />
      )}
    </>
  );
};

export default Header;
