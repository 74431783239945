import { useContext } from "react";
import { DataContext } from "../../store/data-context";

import parse from "html-react-parser";

import ProgramItem from "./ProgramItem";

import classes from "./program.module.scss";

const Program = () => {
  const { data } = useContext(DataContext);

  return (
    <div className={classes.program} id="program-wyborczy">
      <div className={classes.titleContainer}>
        <div className={classes.titleSidebar}></div>
        <div className={classes.title}>
          {parse(data.homeCFS.s_program_title)}
        </div>
      </div>

      {data.homeCFS.s_program_loop.map((item, index) => (
        <ProgramItem
          key={index}
          image={item.photo}
          content={item.text}
          isEven={index % 2 !== 0}
        />
      ))}
    </div>
  );
};

export default Program;
