import { useContext } from "react";
import { DataContext } from "../../store/data-context";

import { FaBars } from "react-icons/fa";

import classes from "./mobileNavigation.module.scss";

const MobileNavigation = (props) => {
  const { data } = useContext(DataContext);

  return (
    <nav
      className={`${classes.navigationContainer} ${
        props.isActive ? classes.navigationActive : undefined
      }`}
    >
      <ul className={classes.navigationList}>
        {data.menus.primary.map((item, index) => (
          <li
            key={index}
            onClick={() => {
              props.closeHandler();
            }}
          >
            <a href={item.url}>{item.title}</a>
          </li>
        ))}
        <li key="wykaz">
          <a
            href="#"
            onClick={() => {
              window.open(data.homeCFS.rejestr_wplat);
            }}
          >
            Rejestr wpłat
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavigation;
