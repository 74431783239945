import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "../../styles/swiperStyles.scss";

import parse from "html-react-parser";

import { useContext } from "react";
import { DataContext } from "../../store/data-context";

import classes from "./ElectoralDistricts.module.scss";
import ElectoralDistrictsItem from "./ElectoralDistrictsItem";

const ElectoralDistricts = () => {
  const { data } = useContext(DataContext);

  return (
    <div className={classes.electoralDistricts} id="okregi-wyborcze">
      <div className={classes.titleContainer}>
        <div className={classes.titleSidebar}></div>
        <div className={classes.title}>
          {parse(data.homeCFS.s_districts_title)}
        </div>
      </div>
      <div className={`${classes.electoralDistrictsContainer} electoralSlider`}>
        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={60}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          slidesPerView={"auto"}
          navigation
        >
          {data.homeCFS.s_districts.map((item, index) => (
            <SwiperSlide key={index}>
              <ElectoralDistrictsItem image={item.districts_photo} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ElectoralDistricts;
