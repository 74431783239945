import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../store/data-context";
import parse from "html-react-parser";

import classes from "./hero.module.scss";
import GetImageByID from "../../utils/functions/getImageById";
import useIsMobile from "../../utils/hooks/use-is-mobile";

const Hero = () => {
  const isMobile = useIsMobile();
  const [bgImage, setBgImage] = useState();
  const [photoImage, setPhotoImage] = useState();

  const { data } = useContext(DataContext);

  useEffect(() => {
    const fetchImages = async () => {
      const bg = await GetImageByID(data.homeCFS.hero_bg);
      const photo = await GetImageByID(data.homeCFS.hero_photo);

      setBgImage(bg);
      setPhotoImage(photo);
    };

    fetchImages();
  }, []);

  return (
    <div className={classes.hero}>
      <div className={classes.colorOverlay} />

      <div className={classes.contentContainer}>
        {isMobile ? (
          <>
            <div className={classes.textContainer}>
              {parse(data.homeCFS.hero_slogan)}
            </div>

            <img src={photoImage} className={classes.photoImage} />
          </>
        ) : (
          <img
            src="https://jacekklimek.pl/wordpress/wp-content/uploads/2024/03/rysunek-1.svg"
            className={classes.photoImageDesktop}
          />
        )}
      </div>
    </div>
  );
};

export default Hero;
