import { API_BASE } from "../routes";

const GetImageByID = async (id) => {
  const res = await fetch(`${API_BASE}/wp-json/wp/v2/media/${id}`);

  const parseRes = await res.json();

  return parseRes.link;
};

export default GetImageByID;
