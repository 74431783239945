import classes from "./ElectoralDistrictsItem.module.scss";

const ElectoralDistrictsItem = ({ image }) => {
  console.log(image);
  return (
    <div className={classes.electoralDistrictsItem}>
      <img src={image} />
    </div>
  );
};

export default ElectoralDistrictsItem;
