import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../store/data-context";

import parse from "html-react-parser";

import classes from "./president.module.scss";
import GetImageByID from "../../utils/functions/getImageById";
import useIsMobile from "../../utils/hooks/use-is-mobile";

const President = () => {
  const isMobile = useIsMobile();
  const [presidentImage, setPresidentImage] = useState("");

  const { data } = useContext(DataContext);

  useEffect(() => {
    const fetchImage = async () => {
      const res = await GetImageByID(data.homeCFS.s_pres_photo);
      setPresidentImage(res);
    };

    fetchImage();
  }, []);

  return (
    <div className={classes.presidentContainer} id="kandydat-na-prezydenta">
      <div className={classes.sideName}>
        <div className={classes.sidebar}></div>
        {data.homeCFS.s_pres_left_text}
      </div>

      <div className={classes.president}>
        <div className={classes.contentContainer}>
          {parse(data.homeCFS.s_pres_text)}
        </div>
        <div className={classes.imageContainer}>
          <img src={presidentImage} />
        </div>
      </div>
    </div>
  );
};

export default President;
