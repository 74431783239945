import { useEffect, useState } from "react";
import parse from "html-react-parser";

import { Helmet } from "react-helmet";

import "./styles/app.scss";
import { DATA_FETCH_ROUTE } from "./utils/routes";
import { DataContext } from "./store/data-context";
import Header from "./components/header/Header";
import Hero from "./components/hero/Hero";
import President from "./components/presentation/President";
import Candidates from "./components/candidates/Candidates";
import Program from "./components/program/Program";
import Footer from "./components/footer/Footer";
import ElectoralDistricts from "./components/electoral-districts/ElectoralDistricts";

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      const res = await fetch(DATA_FETCH_ROUTE);

      if (!res.ok) {
        return;
      }

      const parseRes = await res.json();

      setIsLoading(false);
      setData(parseRes);
    }
    fetchData();
  }, []);

  return (
    <DataContext.Provider value={{ data: data }}>
      <Helmet>
        <title>Jacek Klimek - kandydat na prezydenta</title>
        {data && <meta name="description" content={data.description} />}
      </Helmet>
      <div className="App">
        {data && (
          <>
            <Header />
            <Hero />
            <President />
            <Candidates />
            <Program />
            <ElectoralDistricts />
            <Footer />
          </>
        )}
      </div>
    </DataContext.Provider>
  );
}

export default App;
