import classes from "./footer.module.scss";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <p>Realizacja:</p>
        <a
          href="https://brodnet.pl"
          class="brodnet-logo"
          title="Brodnet - Aplikacje Internetowe"
        >
          <img src="/logo-white.svg" alt="Brodnet - Aplikacje Internetowe" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
